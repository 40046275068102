import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckoutHeaderComponent } from 'src/app/components/checkout/checkout-header/checkout-header.component';
import { PartialModule } from '../../partial/partial.module';

@NgModule({
  declarations: [
    CheckoutHeaderComponent  
  ],  
  exports: [
    CheckoutHeaderComponent
  ],
  imports: [
    CommonModule,
    PartialModule
  ]
})
export class CheckoutHeaderModule{}
