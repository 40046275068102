import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DestinoBannerComponent } from 'src/app/components/destino/destino-banner/destino-banner.component';
import { PartialModule } from '../../partial/partial.module';

@NgModule({
  declarations: [
    DestinoBannerComponent
  ],
  exports: [
    DestinoBannerComponent
  ],
  imports: [
    CommonModule,
    PartialModule 
  ]
})
export class DestinoBannerModule{}
