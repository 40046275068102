<div class="checkout-header">
      <ul>
          <li [ngClass]="{'active': active == 1}">
              <div class="icon">
                <i class="fa-solid fa-cart-shopping"></i>
              </div>
              <span>Meu Pedido</span>
          </li>
          <li [ngClass]="{'active': active == 2}">
              <div class="icon">
                <i class="fa-solid fa-money-bill-1-wave"></i>
              </div>
              <span>Pagamento</span>
          </li>
          <li [ngClass]="{'active': active == 3}">   
              <div class="icon">
                  <i class="fa-solid fa-circle-check"></i>
              </div>
              <span>Confirmação</span>
          </li>
      </ul>    
</div>    
  