import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'checkout-header',
  templateUrl: './checkout-header.component.html',
  styleUrls: ['./checkout-header.component.scss']
})
export class CheckoutHeaderComponent implements OnInit {

  @Input("active") active:any = 1;

  constructor(){} 

  /**
   * 
   * Init
   * 
   ***/
  ngOnInit():void{}

}
