import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'destino-banner',
  templateUrl: './destino-banner.component.html',
  styleUrls: ['./destino-banner.component.scss']
})
export class DestinoBannerComponent implements OnInit {

  @Input("title") title:any         = "Barreirinhas";
  @Input("subtitle") subtitle:any   = "A porta de entrada para Parque Nacional dos Lençóis Maranhenses";
  @Input("image") image             = "banner-destino.jpg";
  @Input("imageMobile") imageMobile = "banner-destino-mobile.jpg";
  @Input("base") base               = "/images/conteudo/destino/banner/"

  public width:any = 0;

  constructor(){}

  /**
   * 
   * On resize
   * 
   */
  onResize(event:any){ 

    this.width = window.innerWidth

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{
    this.width = window.innerWidth
  }


}
