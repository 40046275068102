<div class="destination-banner btn-effect" (window:resize)="onResize($event)">
        <div class="content">
            <h1 class="title wow slideInUp" data-wow-delay="0.5s">{{title}}</h1>
            <span class="subtitle wow fadeIn" data-wow-delay="0.8s">
                {{subtitle}}
            </span>
        </div>
        <figure *ngIf="width > 700">
            <img src="{{base}}{{image}}" [alt]="title" width="100%" />
        </figure> 
        <figure *ngIf="width <= 700">   
            <img src="{{base}}mobile/{{imageMobile}}" [alt]="title" width="100%" />
        </figure> 
      <div class="bg"></div>   
  </div>